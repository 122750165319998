.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-display: fallback;
  font-size: 15px;
}
#nprogress .bar {
  z-index: 9999 !important;
}
.crm-Print__show {
  display: none;
}
@media print {
  .crm-Print__hidden {
    display: none !important;
  }
  .crm-Print__tableRow {
    display: table-row !important;
  }
}
body {
  overscroll-behavior-y: contain;
  touch-action: manipulation;
  scrollbar-color: #8c8c8c #e6e6e6;
  outline: none !important;
  scrollbar-gutter: stable;
}
b {
  font-weight: 500;
}
body * {
  scrollbar-width: thin;
}
*:focus {
  outline: none;
}
button {
  border: 0;
  background: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0 !important;
  height: 0 !important;
  background-color: #f5f6fa;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-clip: content-box;
  background-color: rgba(255, 255, 255, 0.4);
}
::-webkit-scrollbar-thumb:hover {
  background-clip: content-box;
  background-color: #8c8c8c;
}
::-webkit-scrollbar-corner {
  background-color: #e6e6e6;
}
@media (min-width: 767px) {
  ::-webkit-scrollbar {
    width: 9px !important;
    height: 9px !important;
  }
}
.crm-Main {
  display: grid;
  min-height: 100vh;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
@media print {
  .crm-Main {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr !important;
  }
}
@media (min-width: 767px) {
  .crm-Main {
    grid-template-columns: 71px 1fr;
    grid-template-rows: 45px 1fr;
  }
  .crm-Main__expanded {
    grid-template-columns: 245px 1fr;
  }
}
.crm-Main__pointer {
  cursor: pointer;
}
.crm-Main__hidden {
  visibility: hidden;
  display: none;
}
.crm-Main__notOverflow {
  overflow: hidden;
}
.crm-Main__opacity {
  opacity: 0.85;
  z-index: 2;
}
.crm-Main__opacity:hover {
  opacity: 1;
}
.crm-Container {
  padding: 0;
  display: grid;
}
.crm-Container__fullSize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
.crm-Segment {
  padding: 0 !important;
  margin: 0.6em !important;
  border: 0 !important;
}
.pushable > .pusher:after {
  min-height: 350%;
}
@media (max-width: 767px) {
  .crm-Main__hiddenMobile {
    visibility: hidden;
    display: none;
  }
}
.crm-Main__hiddenDesktop {
  visibility: visible;
}
@media (min-width: 767px) {
  .crm-Main__hiddenDesktop {
    visibility: hidden;
    display: none !important;
  }
}
.crm-Main__itemErrors {
  position: sticky !important;
  top: 1px;
  z-index: 10;
}
.crm-Login {
  background-position-x: -200px;
  background-size: auto;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
@media (min-width: 767px) {
  .crm-Login {
    background-size: cover;
  }
}
.crm-Login__box {
  color: #3e5569;
  outline: none;
  background: #fff;
  padding: 20px;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  max-width: 500px;
  min-width: 300px;
  width: 90%;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  position: absolute;
}
.crm-Login__box_forgetLink {
  width: 150px !important;
}
.crm-Flex__center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.crm-Flex__verticalCenter {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .ui.inverted.page.modals {
    padding: 0;
  }
}
@media (max-width: 767px) {
  div.ui.modal.fullscreen {
    margin: 0 !important;
    width: 100vw !important;
  }
}
.swiper-container {
  height: 100%;
}
.swiper-container * img {
  object-fit: cover;
}
:root {
  --swiper-theme-color: #443e35;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.react-trello-lane > div::-webkit-scrollbar {
  display: none !important;
}
.crm-Noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
